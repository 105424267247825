import consumer from './consumer'
import {setupLiveToggle, isLive} from './liveToggle'
import debouncedRefresh from './debouncedRefresh'

$(document).on('turbolinks:load', function() {
  const config = $('#cable')?.data() || {}

  if (config.channel == 'DevicesChannel') {
    const {channel, deviceId, updates} = config
    const fastRefresh = debouncedRefresh(1000)
    const slowRefresh = debouncedRefresh(5000)

    setupLiveToggle(channel)

    consumer.subscriptions.create({channel: channel, deviceId: deviceId}, {
      received(data) {
        if (updates.includes(data.update)) {
          switch(data.update) {
            case 'new-check-ins':
              if (isLive(config.channel)) {
                fastRefresh.next()
              }
              break
            case 'new-status-logs':
              if (isLive(config.channel)) {
                slowRefresh.next()
              }
              break
            default:
              throw `Unmatched update on ${channel}: ${data}`
          }
        }
      }
    })
  }
})

