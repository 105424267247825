import 'selectize/dist/js/selectize.min.js';
import 'selectize/dist/css/selectize.css';
import 'selectize/dist/css/selectize.default.css';

export default function selectize() {
  $('.selectize-athlete').selectize({
    sortField: 'text',
    create: true,
  });
};
