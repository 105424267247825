export default function setupRfidTagInputs() {
  $('.js-rfid-tag-assign').on('click', event => {
    event.preventDefault()
    const container = $(event.currentTarget)
    const form = container.next('.js-rfid-tag-assign-form')

    container.toggleClass('d-none')
    form.toggleClass('d-none')
    form.children('input[type="text"]').focus()
  })
}
