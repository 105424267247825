import {Subject, debounceTime} from 'rxjs'

export default function debouncedRefresh(debounce) {
  const refreshSubject = new Subject()
  const subscription =
    refreshSubject.
      pipe(debounceTime(debounce)).
      subscribe(_e => window.location.reload())

  return refreshSubject
}
