export default function quickTimeAdjust() {
  $('.js-quick-time-adjust').on('click', function (event) {
    event.preventDefault()
    event.stopPropagation()

    const target = $(event.currentTarget)
    const adjust = target.data('adjust')
    const field = target.siblings('input[type=text]')[0]


    const timestamp = new Date(field.value)
    timestamp.setSeconds(timestamp.getSeconds() + adjust)

    field.value = timestamp.toISOString()
  })
}
