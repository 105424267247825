export default function startClock() {
  const clock = $('#clock')

  if (clock) {
    setInterval(() => {
      const today = new Date()
      clock.text(today.toTimeString().split(' ')[0])
    }, 1000)
  };
};
