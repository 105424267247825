export default function setupDarkModeToggle() {
  const toggle = $('#dark-mode-toggle')

  if (toggle.length > 0) {
    if (window.localStorage.getItem('dark-mode')) {
      const enabled = window.localStorage.getItem('dark-mode') == 'enabled'
      console.log(enabled)

      toggle.attr('checked', enabled)
      toggleDarkMode(enabled)
    }

    toggle.on('change', e => {
      const enabled = e.currentTarget.checked
      console.log(enabled)

      window.localStorage.setItem('dark-mode', enabled ? 'enabled' : 'disabled')
      toggleDarkMode(enabled)
    })
  }
}

function toggleDarkMode(enabled) {
  $('body').toggleClass('dark', enabled)
  $('table').toggleClass('table-dark', enabled)
  $('table').toggleClass('table-striped', !enabled)
}
