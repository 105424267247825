import consumer from './consumer'
import {setupLiveToggle, isLive} from './liveToggle'
import debouncedRefresh from './debouncedRefresh'

$(document).on('turbolinks:load', function() {
  const config = $('#cable')?.data() || {}

  if (config.channel == 'JuryAppClientsChannel') {
    const {channel, eventId, updates} = config
    const refresh = debouncedRefresh(1000)

    setupLiveToggle(channel)

    consumer.subscriptions.create({channel: channel, eventId: eventId}, {
      received(data) {
        if (updates.includes(data.update)) {
          refresh.next()
        }
      }
    })
  }
})

