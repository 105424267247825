export function isLive(channel) {
  return window.localStorage.getItem(channel) == 'live'
}

export function setupLiveToggle(channel) {
  const toggle = $('#live-toggle')

  if (toggle.length > 0) {
    if (window.localStorage.getItem(channel)) {
      toggle.attr('checked', window.localStorage.getItem(channel) == 'live')
    } else {
      window.localStorage.setItem(channel, toggle.attr('checked') ? 'live' : 'paused')
    }

    toggle.on('change', e => window.localStorage.setItem(channel, e.currentTarget.checked ? 'live' : 'paused'))
  } else {
    window.localStorage.setItem(channel, 'live')
  }
}

